<template>
  <div class="feed-page">
    <div class="filter">
      <div class="container">
        <ul class="text-white hidden-sm-and-down">
          <li
            v-for="(tab) in tabs"
            :id="`activate-${tab.id}`"
            :key="tab.id"
            :class="{ active : isActive == tab.id }"
            @click="activate(tab.id)"
          >
            {{ tab.title }}
          </li>
          <span
            v-loading="campaignsIsLoading"
            class="tab-loader"
          ></span>
        </ul>
        <div class="mobile-filter hidden-md-and-up">
          <el-select v-model="isActive">
            <el-option
              v-for="(tab) in tabs"
              :key="tab.id"
              :label="tab.title"
              :value="tab.id"
              @click="activate(tab.id)"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <keep-alive>
      <feed-tab
        v-for="tab in tabs"
        v-if="isActive == tab.id"
        :key="tab.id"
        :tab="tab"
        :bottom="bottom"
        :is-active="isActive"
        :static-tab-count="staticTabCount"
      ></feed-tab>
    </keep-alive>
  </div>
</template>

<script>
import feedApi from '@/modules/feed/services/feed.api'
import feedTab from '@/modules/feed/components/feed-tab'

export default {
  components: {
    feedTab
  },

  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'All',
          tags: [],
          search: '',
          order: '',
          page: 1,
          perPage: 12,
          totalPages: 0,
          items: []
        },
        {
          id: 2,
          title: 'Articles',
          tags: [],
          search: '',
          order: '',
          sort: '',
          sortOptions: [
            { value: '', label: 'Recent' },
            { value: 'popularity', label: 'Popular' }
          ],
          page: 1,
          perPage: 12,
          totalPages: 0,
          items: []
        },
        {
          id: 3,
          title: 'Events',
          tags: [],
          search: '',
          order: '',
          sort: '',
          sortOptions: [
            { value: '', label: 'Recent' },
            { value: 'popularity', label: 'Popular' }
          ],
          page: 1,
          perPage: 12,
          totalPages: 0,
          items: []
        },
        {
          id: 4,
          title: 'Offers',
          search: '',
          order: '',
          sort: '',
          sortOptions: [
            { value: '', label: 'Recent' },
            { value: 'reward_target', label: 'Points low to high' },
            { value: 'to_low', label: 'Points high to low' }
          ],
          page: 1,
          perPage: 12,
          totalPages: 0,
          items: []
        },
        {
          id: 5,
          title: 'Key info',
          tags: [],
          search: '',
          order: '',
          sort: '',
          sortOptions: [
            { value: '', label: 'Recent' },
            { value: 'popularity', label: 'Popular' }
          ],
          page: 1,
          perPage: 12,
          totalPages: 0,
          items: []
        },
      ],
      userTags: [],
      staticTabCount: 5,
      activeTab: null,
      isActive: 1,
      campaignsIsLoading: false,
      bottom: false,
    }
  },

  computed: {
    hasCampaigns() {
      return this.tabs.length > 5
    }
  },

  created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    });

    this.fetchCampaigns()
    this.fetchTags()
  },

  mounted() {
    if (this.$route.params.isActiveFilter) {
      document.getElementById(`activate-${this.$route.params.isActiveFilter}`).click();
    }
  },

  methods:{
    activate(el) {
      this.isActive = el
    },
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight

      return bottomOfPage || pageHeight < visible
    },
    fetchCampaigns() {
      this.campaignsIsLoading = true

      feedApi
        .campaigns
        .index()
        .then((response) => {
          this.campaignsIsLoading = false
          this.campaigns = response.data.data
          // this.campaigns = response.data.data.filter(campaign => campaign.slug === 'be-better-september')
          this.tabs.push(..._.map(this.campaigns, (campaign) => {
            return { id: campaign.id + this.staticTabCount, title: campaign.name, tags: campaign.tags.data, search: '', page: 1, perPage: 12, totalPages: 0, items: [] }
          }))
        });
    },
    fetchTags() {
      feedApi
        .tags()
        .then((response) => {
          _.each(this.tabs, (tab) => {
            if (tab.id <= 5) {
              tab.tags = response.data.data
            }
          })
        })
    },
  },
}
</script>

<style lang="scss">
.feed-page {
	.el-select {
		.el-loading-spinner {
			margin-top: -28px;
		}
	}

  .articles {
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 580px;
    .el-col-articles {
      margin-bottom: 30px;
    }
  }
  .filter {
    height: 55px;
    background-color: $feed-banner-primary-bg-color;
    color: $feed-banner-primary-fg-color;
    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      padding-top: 11.5px;
      li {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        padding: 7.5px 33px;
        border-radius: 16.5px;
        margin-right: 12px;
        cursor: pointer;
        transition: .3s ease-in;
        @media (max-width: 1200px) {
          padding: 7.5px 22px;
        }
        @media (max-width: 991px) {
          padding: 7.5px 10px;
          font-size: 12px;
        }
      }
      .active {
        background-color: $feed-banner-primary-bg-active-color;
        color: $feed-banner-primary-fg-active-color;
      }
      .tab-loader {
          min-width: 50px;
          .el-loading-mask .circular circle {
              stroke: #FFFFFF;
          }
          .el-loading-spinner {
              margin-top: -15px;
              .circular {
                  width: 32px;
                  height: 32px;
              }
          }
      }
    }
    .mobile-filter {
      .el-select {
        padding-top: 7px;
        color: $white-color;
        cursor: pointer;
        display: block;
        input {
          background: $white-color;
          color: $primary-color;
          border: none;
          border-radius: 4px;
          padding: 12px;
          height: auto;
          line-height: normal;
          font-size: 13px;
          font-weight: bold;
        }
        i {
          margin-left: 90px;
          color: $primary-color;
          font-weight: 900;
          @media (max-width: 416px) {
            margin-left: 0;
            float: right;
          }
        }
      }
    }
  }
  .search-sort-filter {
    background-color: $white-color;
    height: 55px;
    line-height: 55px;
    @media (max-width: 767px) {
      height: auto;
      line-height: normal;
      padding-bottom: 15px;
    }
    .left-side {
      p {
        margin: 0;
        color: $primary-grey-color;
        font-size: 12px;
        font-weight: bold;
        @media (max-width: 767px) {
          margin-top: 15px;
        }
      }
    }
    .right-side {
      text-align: right;
      @media (max-width: 767px) {
        text-align: left;
        margin-top: 15px;
      }
      &>div {
        display: inline-flex;
        align-items: center;
        @media (max-width: 767px) {
          align-items: end;
          display: block;
        }
      }
      .txt {
        font-size: 12px;
        font-weight: bold;
        min-width: 40px;
        margin-right: 23px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .feed-tag-input {
        margin-right: 20px;
        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
        .el-input__inner {
          text-overflow: ellipsis;
        }
      }
      .drop-sel {
        margin-right: 31px;
        @media (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
        .el-select {
          color: $primary-color;
          cursor: pointer;
          @media (max-width: 767px) {
            display: block;
          }
          input {
            color: $primary-color;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $border-color;
            padding: 0 6px 6px 6px;
            height: auto;
            line-height: normal;
            font-size: 13px;
            font-weight: bold;
          }
          i {
            margin-left: 90px;
            color: $primary-color;
            font-weight: 900;
            @media (max-width: 416px) {
              margin-left: 0;
              float: right;
            }
          }
        }
      }
      .search {
        @media (max-width: 416px) {
          margin-top: 10px;
        }
        input {
          color: $primary-color;
          font-size: 13px;
          font-weight: bold;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid $border-color;
          padding: 0 0 6px 6px;
          height: auto;
          line-height: normal;
        }
        .el-input__suffix {
          right: -5px;
          i {
            color: $primary-color;
            font-size: 16px;
          }
        }
        .el-loading-spinner {
          text-align: right;
          right: 20px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .no-result {
    margin-top: 81px;
    .el-col {
      float: none;
      margin: 0 auto;
      text-align: center;
      h2 {
        margin: 21px 0 0 0;
      }
      p {
        font-size: 14px;
        max-width: 304px;
        margin: 18px auto;
      }
    }
  }
}
</style>
